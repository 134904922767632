import PropTypes from 'prop-types';
import { getCurrencyDataById, currenciesData, stepsCount } from 'lib/formRangeData';
import { formatNumberToUnits } from 'utils/helpers';
import ReactSlider from 'react-slider';

const PriceRange = ({ onInput, value }) => {
  const initialData = getCurrencyDataById(value.id);

  const step = (initialData.max - initialData.min) / stepsCount;
  const minDistance = (initialData.max - initialData.min) / 5;

  const onPriceChange = (newValues) => {
    const [newMinValue, newMaxValue] = newValues;
    onInput('price', { ...value, min: newMinValue, max: newMaxValue });
  };

  const onCurrencyChange = (id) => {
    onInput('price', getCurrencyDataById(id));
  };

  const renderThumb = ({ key, ...props }, state) => {
    return (
      <button {...props} key={key} type="button">
        <div className="value">{formatNumberToUnits(state.valueNow)}</div>
        <div className="thumb"></div>
      </button>
    );
  };

  return (
    <div className="price-range">
      <div className="price-range__currency">
        <h2>Currency</h2>
        {currenciesData.map((currencyData, index) => (
          <button
            key={index}
            className={value.currency === currencyData.currency ? 'active' : ''}
            onClick={() => onCurrencyChange(currencyData.id)}
            type="button"
          >
            {currencyData.currency}
          </button>
        ))}
      </div>
      <ReactSlider
        className="price-range__slider"
        thumbClassName="thumb-wrapper"
        renderThumb={renderThumb}
        onChange={onPriceChange}
        value={[value.min, value.max]}
        min={initialData.min}
        max={initialData.max}
        step={step}
        minDistance={minDistance}
        withTracks={false}
        pearling
      />
    </div>
  );
};

PriceRange.propTypes = {
  onInput: PropTypes.func,
  value: PropTypes.shape({
    currency: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    id: PropTypes.number
  })
};

export default PriceRange;
