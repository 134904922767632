import contactsStore from 'store/contactsStore';
import newsStore from 'store/newsStore';
import ReactDOM from 'react-dom/client';
import App from './App';

import 'react-phone-number-input/style.css';
import 'swiper/css/bundle';
import 'assets/sass/index.sass';

import '@fontsource/inter/700.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/300.css';

Promise.all([contactsStore.loadData(), newsStore.loadList()])
  .catch(() => {})
  .finally(() => {
    ReactDOM.createRoot(document.getElementById('root')).render(<App />);
  });
