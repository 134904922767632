export const currenciesData = [
  {
    id: 1,
    currency: 'AED',
    min: 500 * Math.pow(10, 3),
    max: 50 * Math.pow(10, 6)
  },
  {
    id: 2,
    currency: 'USD',
    min: 150 * Math.pow(10, 3),
    max: 15 * Math.pow(10, 6)
  },
  {
    id: 3,
    currency: 'EUR',
    min: 130 * Math.pow(10, 3),
    max: 13 * Math.pow(10, 6)
  }
];

export const getCurrencyDataById = (id = 1) => {
  return currenciesData.find((currency) => currency.id === id);
};

export const stepsCount = Math.pow(10, 4);
