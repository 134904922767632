import { FreeMode, Scrollbar, Mousewheel } from 'swiper';
import { useLoaderData, Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import interfaceLinks from 'store/interfaceLinks';
import interfaceStore from 'store/interfaceStore';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import moment from 'moment';

const Page = observer(() => {
  const { data } = useLoaderData();

  useEffect(() => {
    interfaceStore.setRightLink({
      ...interfaceLinks.home,
      href: `/${interfaceLinks.news.href}`
    });
  }, []);

  return (
    <div className="page news-modal">
      <div className="news-block">
        <div className="news-block__header">
          <div className="news-block__header-date">{moment(data.date).format(`DD.MM.YY`)}</div>
          <Link className="news-block__header-close" to="/#news-section">
            Close
          </Link>
        </div>

        <div className="news-block__content">
          <div className="news-block__content-preview">
            <img src={data.preview.url} />
          </div>
          <div className="news-block__content-text">
            <h1>{data.title}</h1>
            <p>{data.data}</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Page;
