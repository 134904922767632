import interfaceLinks, { getRightLink } from 'store/interfaceLinks';
import { Swiper, SwiperSlide } from 'swiper/react';
import interfaceStore from 'store/interfaceStore';
import { scrollProgress } from 'utils/helpers';
import { useEffect, useRef } from 'react';
import newsStore from 'store/newsStore';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import NewsItem from 'components/NewsItem';

const News = observer(() => {
  const swiperRef = useRef(null);
  const ref = useRef(null);

  const newsGroupsCount = Math.ceil(newsStore.news.length / 2);
  const newsCount = newsStore.news.length;

  useEffect(() => {
    const fullRange = scrollProgress(ref.current, [0.0, 1.0]);

    const onScroll = () => {
      const groupsIndex = Math.floor(fullRange.interpolate([0, newsGroupsCount]));
      const index = Math.floor(fullRange.interpolate([0, newsCount]));

      const intermediateMedia = '(min-aspect-ratio: 1 / 1) and (max-aspect-ratio: 1700 / 1080)';

      if (window.matchMedia(intermediateMedia).matches) {
        swiperRef.current?.swiper?.slideTo(Math.min(Math.max(index, 0), newsCount - 1));
      } else {
        swiperRef.current?.swiper?.slideTo(Math.min(Math.max(groupsIndex, 0), newsGroupsCount - 1));
      }

      const rightLink = getRightLink(ref.current, interfaceLinks.form);
      if (rightLink) {
        interfaceStore.setRightLink(rightLink);
      }
    };

    onScroll();

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <section
      style={{ '--count': newsCount, '--groups-count': newsGroupsCount }}
      className="news-section"
      id="news-section"
      ref={ref}
    >
      <div className="news-section__sticky">
        <div className="news-section__title">
          <h2>News</h2>
          <span>{newsStore.news.length}</span>
        </div>

        <Swiper
          className="news-section__list news-section__list--desktop"
          allowTouchMove={false}
          direction={'vertical'}
          slidesPerView={1}
          spaceBetween={0}
          ref={swiperRef}
          speed={500}
        >
          {newsStore.news.map((item) => (
            <SwiperSlide key={item.documentId}>
              <Link to={`/news/${item.documentId}`}>
                <NewsItem data={item} />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="news-section__list news-section__list--mobile">
          <Swiper slidesPerView={1} spaceBetween={10}>
            {newsStore.news.map((item) => (
              <SwiperSlide key={item.documentId}>
                <Link to={`/news/${item.documentId}`}>
                  <NewsItem data={item} />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
});

export default News;
