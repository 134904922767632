import services4 from 'assets/img/services/services-4.webp';
import services3 from 'assets/img/services/services-3.webp';
import services2 from 'assets/img/services/services-2.webp';
import services1 from 'assets/img/services/services-1.webp';

export default [
  {
    id: 1,
    img: services1,
    title: 'Off-Plan Property Sales',
    description:
      'We offer expert guidance and exclusive early access to Dubai’s most promising new developments, ensuring our clients secure the best deals at the earliest stages.'
  },
  {
    id: 2,
    img: services2,
    title: 'Secondary Market Sales',
    description:
      'Our extensive network and market knowledge enable us to offer a wide selection of high-quality resale properties, catering to diverse preferences and budgets.'
  },
  {
    id: 3,
    img: services3,
    title: 'Property Management',
    description:
      'We offer full-service property management solutions, including maintenance, tenant relations, and financial reporting, to ensure your investments are well-cared for and profitable.'
  },
  {
    id: 4,
    img: services4,
    title: 'Flipping & Investment',
    description:
      'Our team specializes in identifying lucrative investment opportunities and executing successful flipping projects, maximizing returns for our clients.'
  }
];
