import { createBrowserRouter, Navigate } from 'react-router-dom';

import propertyTypes from './propertyTypes';
import services from './services';
import privacy from './privacy';
import Layout from './layout';
import about from './about';
import terms from './terms';
import home from './home';
import news from './news';
import wip from './wip';

const layoutRoutes = {
  path: '/',
  element: <Layout />,
  errorElement: <Navigate to="/" />,
  children: [propertyTypes, services, news, about, home]
};

const routes = [wip, terms, privacy, layoutRoutes];

export const router = createBrowserRouter(routes);
